import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG, trimSentence } from "../components/common/functions"
import BlockAnnuaire from "../components/common/blockAnnuaire/blockAnnuaire"

export default function Rubriques({
  data: { nodeAccueilRubrique: rubrique },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"
  const HeroBanner = React.lazy(() =>
    import("../components/common/heroBanner/heroBanner")
  )
  const BlockPushProduit = React.lazy(() =>
    import("../components/common/blockPushProduit/blockPushProduit")
  )
  const BlockInstagramSlider = React.lazy(() =>
    import("../components/common/blockInstagramSlider/blockInstagramSlider")
  )

  const {
    title,
    field_couleur_d_ambiance: colorInsta,
    field_caption,
    field_background_color: colorMea,
    field_description: description,
    field_title,
    field_seo,
    relationships: {
      field_hero: heroItems,
      fieldrubrique,
      field_background_image: bgMea,
      field_image_totem: bgInsta,
    },
  } = rubrique

  const intl = useIntl()

  const carouselFiltered = heroItems.filter(
    hero => hero.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  let articlesArray = []
  let articles = []

  if (fieldrubrique && fieldrubrique.relationships) {
    const allArticles = articlesArray.concat(
      fieldrubrique.relationships.node__article_experience,
      fieldrubrique.relationships.node__article_blog,
      fieldrubrique.relationships.node__article_activite,
      fieldrubrique.relationships.node__article_hotel
    )
    articles = allArticles.filter(a => a !== null && a.title !== "Dummy")
    articles.sort(
      (a, b) =>
        (a.field_weight != null ? a.field_weight : Infinity) -
        (b.field_weight != null ? b.field_weight : Infinity)
    )
  }

  const mea = { field_title, field_caption, colorMea, description, bgMea }
  const instagramFields = {
    field_title,
    field_caption,
    colorInsta,
    description,
    bgInsta,
  }

  // If meta description is not set use description as meta description
  if (!seoData.meta.description) {
    //Trim html tags from description
    seoData.meta.description = description?.value ? trimSentence(description.value, 200) : "";
  }

  //Get specific discover page by id
  let isDiscoverPage

  if (
    pageContext.id === "4d681417-3379-54ac-9b21-276ea3f6a156" ||
    pageContext.id === "5e58fd96-17a8-5589-8449-4c0daa553abc"
  ) {
    isDiscoverPage = 1
  } else {
    isDiscoverPage = 0
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {carouselFiltered && <HeroBanner hero={carouselFiltered} />}
          <BlockPushProduit articles={articles} isRubrique={1} mea={mea} />
          {isDiscoverPage === 1 && <BlockAnnuaire />}
          <BlockInstagramSlider info={instagramFields} />
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeAccueilRubrique(id: { eq: $id }) {
      ...accueilRubriqueFields
    }
  }
`
